<template>
    <div class="team " :class="{m_team:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span style="color: #fff;">{{ $t('Nav[0].menuList[3]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="team_main">
            <div class="team_main_item" v-for="(value,index) in list">
                <div class="item-img">
                    <img :src="imgUrl(baseUrl,value.personImg, '@t:512x512>')" alt="">
                    <div class="item-text">
                        <p>{{value.personTitle}}</p>
                        <p>{{value.personBs}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "TeamModule",
    components: {
        TitleH
    },
    props:['list'],
    data () {
        return {

        }
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------TeamModule---------- --*/
.team {
    padding: 6.875rem 0;

    &_main {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        &_item {
            width: 18.8125rem;
            flex: 1 0 calc(25% - 10px);
            margin-right: 5px;

            .item-img {
                margin-top: 3.75rem;
transition: all .5s ease-in;
                img {
                    width: 100%;
                }

                .item-text {
                    color: #fff;
                    height: 6.875rem;
                    font-size: 1.375rem;
                    text-align: center;
                    padding: 2.5rem 0;
                    font-family: 'AlibabaPuHuiTiM';
                }

                &:hover{
                    margin-top: 3rem;
                }
            }


        }

    }
}

.m_team {
    width: 100%;

    .team_main {
        justify-content: left;

        &_item {
            width: 30%;
            height: auto;
            margin-right: 1.5rem;

            .item-img {
                .item-text {
                    display: none;
                }
            }

            &:nth-child(4n-1) {
                margin-right: 0;
            }
        }
    }
}
</style>
