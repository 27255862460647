<template>
    <div class="TitleH" :class="{m_TitleH:$store.state.screenType}">
        <div class="main"
             :class="{language:$store.state.language,en_title:$store.state.language&&$store.state.screenType}">
            <slot name="header"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "TitleH"

}
</script>


<style scoped lang="scss">

/*-- -------TitleH---------- --*/
.TitleH {
    .main {
        width: 100%;
        height: 5.125rem;
        background: transparent url("../../assets/duce_01.png") no-repeat bottom center;
        background-size: 21.1875rem auto;
        margin: 0 auto;
        font-size: 2.9rem;
        text-align: center;
        //font-weight: 800;
        font-family: 'PangMenZhengDao';
        span{
            width: 50%;
            display: block;
            margin: 0 auto;
            padding-top: 1rem;
        }
    }

}
.m_TitleH {
    .main {
        font-size: 2.5rem;
        line-height: 5.125rem;
        background-size: 16.0625rem auto;
    }
}

.en_title {
    font-size: 1.8rem !important;
}
</style>
