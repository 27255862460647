<template>
    <div class="business" :class="{m_business:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[0].menuList[2]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="box-link"></div>
        <div class="business_content" v-if="!$store.state.screenType">
            <div class="items" v-for="(value,index) in list" :key="index" @click="jumpPage(index)" :style="'background-image: url('+imgUrl(baseUrl,value.ImageUrl)+')'">
                <div class="item">
                    <p class="title">{{ value.Text }}</p>
                    <p class="txt">{{ value.Descript }}</p>
                </div>
                <i></i>
            </div>
        </div>
        <div class="business_small" v-if="$store.state.screenType">
            <div class="items" v-for="(value,index) in list" :key="index" @click="jumpPage(index)">
                <div class="item">
                    <p>{{ value.Text  }}</p>
                    <div class="arrow"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "BusinessModule",
    components: {
        TitleH
    },
    props:['list'],
    data () {
        return {

        }
    },
    computed: {
        ...mapState(['baseUrl'])
    },
    methods:{
        imgUrl,
        // 点击跳转链接
        jumpPage (index) {
            this.$router.push({
                path:'/business'
            })
            if (index===3){
                index=2
            }
            this.$store.commit('changeType', 2)
        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------BusinessModule---------- --*/
.business {
    padding: 5rem 0;

    .box-link {
        width: 75%;
        height: 1px;
        background-color: #999;
        margin: 0 auto;
        margin-top: 7.8125rem;
    }

    &_content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .items {
            margin-top: 2.5rem;
            width: 24%;
            height: 16.6875rem;
            background: transparent url("../../../assets/duce_02.png") no-repeat center center;
            background-size: 100% auto;
            position: relative;
            cursor: pointer;
            transition: all 1s ease;
            .item {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 1.5rem;
                color: #fff;
                text-align: center;
                padding: 6.875rem 2.0625rem;
                box-sizing: border-box;
                transition: all .5s ease-in;
                .title{
                    font-size: 2rem;
                }
                .txt {
                    padding-top: 1rem;
                    font-size: 1.125rem;
                    text-align: left;
                    font-family: 'AlibabaPuHuiTiL';
                    opacity: 0;
                    transition: all 1s ease;

                }

            }

            i {
                position: absolute;
                left: calc(50% - 0.875rem);
                bottom: 2.0625rem;
                display: block;
                width: 1.6875rem;
                height: 1.125rem;
                background: transparent url("../../../assets/banner_next.png") no-repeat center center;
                background-size: 100% auto;
            }

            &:after {
                content: '';
                width: 1px;
                height: 1.8125rem;
                background-color: #999999;
                position: absolute;
                bottom: 104.5%;
                left: 50%;
            }

            &:nth-child(3) {

            }

            &:hover{

               .item{
                   padding: 4.375rem 2.0625rem ;
                   background-color: #ED111C ;
                   .txt{
                       opacity: 1;
                   }
               }
            }
        }

    }

    &_small {
        margin-top: 3.8125rem;
        width: 100%;

        .items {
            width: 100%;
            height: 7.375rem;
            background-color: #FBFBFB;
            margin-bottom: 1.0625rem;

            &:first-child {
                .item {
                    //line-height: 3.375rem;
                }
            }

            .item {
                font-size: 1.875rem;
                line-height: 7.375rem;
                padding: 0 2.625rem;
                position: relative;

                .arrow {
                    width: 1.5625rem;
                    height: 0.8125rem;
                    background-image: url('../../../assets/m_02.png');
                    background-size: 100% auto;
                    position: absolute;
                    right: 2.625rem;
                    top: calc(50% - 0.4375rem);
                }
            }
        }
    }
}

.m_business {

    .box-link {
        display: none;
    }
}
</style>
