<template>
    <div class="ScrollBar" :class="{m_scrollbar:$store.state.screenType,fix:flag}">
        <nav>
            <div class="title" :class="{en_title:!$store.state.screenType}">
                <div class="title_item" v-for="(value,index) in menulist.menuList" :key="index"
                     @click="scrollClick(index)" :class="{active:$store.state.menuIndex===index}">
                    <span> {{ value }}</span>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "ScrollBar",
    props: ['menulist', 'list'],
    data () {
        return {
            menuIndex: 0,
            flag: false
        }
    },
    methods: {
        scrollClick (index) {
            this.menuIndex = index
            console.log(index)
            this.$emit('barClick', index)
        },
        fixation () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (this.$store.state.screenType) {
                this.flag = (scrollTop >= 600) ? true : false
            } else {
                this.flag = (scrollTop >= 600) ? true : false
            }
        }
    },
    mounted () {
        window.addEventListener('scroll', this.fixation)
    },
    destroyed () {
        window.removeEventListener('scroll', this.fixation)
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------ScrollBar---------- --*/
.ScrollBar {
    width: 100%;
    overflow-x: auto;
    height: 5.25rem;
    background-color: #F0F0F0;

    nav {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 7.5rem;
        box-sizing: border-box;
        background-color: #F0F0F0;
        height: 100%;

        .title {
            display: flex;
            justify-content: left;
            height: 100%;
            transition: all 1s;
            font-size: 1.5rem;
            background-color: #F0F0F0;
            width: auto;

            &_item {
                width: 12.5rem;
                padding: 0 2.75rem;
                border-left: 1px solid #C7C4C7;
                cursor: pointer;
                background-color: #F0F0F0;
                position: relative;

                span {
                    width: 100%;
                    display: block;
                    white-space: normal;
                    font-size: 1.25rem;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }

                &:last-child {
                    border-right: 1px solid #C7C4C7;
                }
            }

            .active {
                border-bottom: 2px solid #ED121B;
                //box-sizing: border-box;
            }
        }

        .en_title {
            .title_item {
                width: 17.5rem;

                span {
                    font-size: 1.2rem;
                }
            }
        }

    }
}

.m_scrollbar {
    width: 100%;

    height: 6rem;

    nav {
        padding: 0 3.125rem;
        width: auto;

        .title {
            width: auto;
            height: 100%;

            &_item {
                height: auto;
                padding: 0 7.8125rem;
                font-size: 1.125rem;
                text-align: center;
                background-color: #F0F0F0;


            }
        }
    }
}

.fix {
    position: fixed;
    top: 6.25rem;
    left: 0;
    z-index: 10;

}


</style>
