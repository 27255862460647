<template>
    <div class="introduce" :class="{m_introduce:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[0].menuList[0]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="introduce_text ql-editor" style="" v-html="list" >
            <!--<quill-editor :content="list"></quill-editor>-->
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'

export default {
    name: "IntroduceModule",
    components: {
        TitleH,

    },
    props: ['list'],
    data () {
        return {}
    }

}
</script>


<style scoped lang="scss">

/*-- -------IntroduceModule---------- --*/
.introduce {
    padding: 6.25rem 1.875rem;

    &_text {
        font-size: 1.225rem;
        line-height: 3rem;
        margin-top: 4.375rem;
        width: 100%;
        height: auto;
        //background-color:#f1f1f1;
        font-family: 'AlibabaPuHuiTiL';


        p {
            margin-top: 3.125rem;
        }
    }
}

.m_introduce {
    padding: 4.8125rem 0;

    &_text {
        text-align: justify;
    }
}
</style>
