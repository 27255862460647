import { render, staticRenderFns } from "./LocationModule.vue?vue&type=template&id=245a54be&scoped=true&"
import script from "./LocationModule.vue?vue&type=script&lang=js&"
export * from "./LocationModule.vue?vue&type=script&lang=js&"
import style0 from "./LocationModule.vue?vue&type=style&index=0&id=245a54be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245a54be",
  null
  
)

export default component.exports