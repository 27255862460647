/**
 * mixins混入，是负责页面的楼层滚动功能
 * @type {{destroyed(): void, data(): {barList: {}, flag: boolean}, watch: {}, methods: {scrollBar(): void, scrollClick(*): void}, mounted(): void}}
 */

export const result = {
    data () {
        return {
            barList: {},//获取需要楼层轮动的dom元素    获取方式为class为scrollBar的元素
            flag: true,
        }
    },
    methods: {
        //当元素被点击的时候，屏幕滚动到指定位置
        scrollClick (index) {
            this.$store.commit('changeIndex', index)
            //console.log(this.barList)
            let height = this.barList[index].offsetTop-100
            console.log(this.barList)
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            })
        },
        //滚动监听事件
        scrollBar () {
            let scrollTop = document.documentElement.scrollTop
            //console.log(scrollTop)
            //滚动事件
            //使用节流，来监视事件触发的频率
            if (this.flag) {
                this.barList.forEach((value, index) => {
                    if (value.offsetTop<= scrollTop+200 && value.offsetTop + value.clientHeight > scrollTop) {
                        this.$store.commit('changeIndex', index)
                        this.flag = false
                    }
                })
                let that = this
                setTimeout(() => {
                    that.flag = true
                }, 500)
            }
        }
    },
    updated() {
        // 页面更新
        console.log('页面更新...')
    },
    mounted () {
        //页面加载完成给浏览器添加监听滚动事件
        window.addEventListener('scroll', this.scrollBar)
        this.$nextTick(() => {
        //获取分类下的各个模块
        this.barList = this.$el.querySelectorAll('.scrollBar')
        //console.log(this.barList)
        console.log('加载滚动条...')
        if (this.$store.state.menuIndex !== -1) {
            console.log('自动滚动...'+this.$store.state.menuIndex)
            this.scrollClick(this.$store.state.menuIndex)
        }
        })
        
    },
    destroyed () {
        //当页面关闭的时候，移出浏览器的监听事件
        window.removeEventListener('scroll', this.scrollBar)
    },
    watch: {}
    
}

