<template>
    <div class="location" :class="{m_location:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[0].menuList[4]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="location_main">
            <div class="main-title">
                <span v-for="(value,index) in list"
                      :key="index"
                      @click="regionChange(index)"
                      :class="{active:type===index}">
                            {{ value.name }}
                </span>
            </div>
            <div class="main-content">
                <div class="list" v-for="(value,i) in list" :key="i"
                     :style="{'display':type===i?'flex':'none'}">
                    <div class="list_item" v-for="(val,index) in value.locations" :key="index">
                        <div>{{ val.cityName }}</div>
                        <p>{{ val.address }}</p>
                        <p>{{ val.zipCode }}</p>    <!--邮编：-->
                        <p>{{ val.tell }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="location_small">
            <span class="ip">公司地址</span>
            <div class="location_small_item" v-for="(value,index) in list" :key="index">
                <div class="item-title">{{ value.name }}
                    <div class="arrows" @click="smallTypeChange(index)" :class="{active:smallType===index}"></div>
                </div>
                <div class="item-location">
                    <div class="item-location_item" v-for="(val,i) in value.locations" :key="i"
                         :class="{active:smallType===index}">
                        <div>{{ val.cityName }}</div>
                        <p>{{ val.address }}</p>
                        <p>{{ val.zipCode }}</p>
                        <p>{{ val.tell }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'

export default {
    name: "LocationModule",
    components: {
        TitleH
    },
    props: ['list'],
    data () {
        return {
            //地点数据
            type: 0,
            //对应的要展开的数值
            smallType: 0
        }
    },
    methods: {
        regionChange (id) {
            this.type = id
        },
        smallTypeChange (index) {
            this.smallType = index
        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------LocationModule---------- --*/
.location {
    padding: 5rem 0;

    &_main {
        padding: 6.25rem 7.8125rem;

        .main-title {
            color: #1F1F1F;
            font-size: 2.25rem;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #C7C4C7;
            font-family: 'AlibabaPuHuiTiM';

            span {
                cursor: pointer;
                padding: 1.125rem 2.75rem;
            }

            .active {
                border-bottom: 0.25rem solid #ED121B;
            }
        }

        .main-content {
            position: relative;
            width: 100%;
            margin: 3.125rem 0;
            height: auto;
            min-height: 12.5rem;

            .list {
                width: 100%;
                //position: absolute;
                //top: 0;
                //left: 0;
                display: none;
                justify-content: left;
                flex-wrap: wrap;
                //opacity: 0;

                &_item {
                    width: 48%;
                    background-color: #F7F9FB;
                    padding: 2.5rem 2.1875rem;
                    box-sizing: border-box;
                    margin-bottom: 1.25rem;
                    margin-right: 4%;
                    text-align: justify;
                    div {
                        font-size: 1.5rem;
                        margin-bottom: 1.5rem;
                    }

                    p {
                        font-size: 1.5rem;
                        font-family: 'AlibabaPuHuiTiL';
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

            }


        }
    }

    &_small {
        padding: 3.125rem 0;
        display: none;

        &_item {

            .item-title {
                width: 100%;
                height: 5.375rem;
                font-size: 1.875rem;
                line-height: 5.3725rem;
                position: relative;
                //font-weight: bold;
                border-bottom: 0.125rem solid #C7C4C7;
                font-family: 'AlibabaPuHuiTiM';

                .arrows {
                    width: 1.5625rem;
                    height: 0.8125rem;
                    background-image: url('../../../assets/m_02.png');
                    background-size: 100% auto;
                    position: absolute;
                    right: 2.625rem;
                    top: calc(50% - 0.4375rem);
                    transform: rotateZ(180deg);
                }

                .active {
                    transform: rotateZ(0);
                    transition: all 0.5s;
                }
            }

            .item-location {
                padding: 1.6875rem 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                &_item {
                    width: 48%;
                    background-color: #F7F9FB;
                    padding: 2.5rem 2.1875rem;
                    box-sizing: border-box;
                    display: none;
                    margin-bottom: 1.25rem;

                    div {
                        font-size: 1.5rem;
                        margin-bottom: 1.5rem;
                    }

                    p {
                        font-size: 1.5rem;
                        font-family: 'AlibabaPuHuiTiL';
                    }
                }

                .active {
                    display: block;
                }
            }
        }
    }
}

.m_location {
    padding: 5.375rem 3.125rem;

    .location_main {
        display: none;
    }

    .location_small {
        display: block;
    }
}
</style>
