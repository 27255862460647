<template>
    <div class="Introduce" :class="{m_introduce:$store.state.screenType}">
        <Navbar @barClick="index"></Navbar>
        <SwiperCom :list="banners" class="swiper" v-if="banners.length"></SwiperCom>
        <ScrollBar :menulist="$t('Nav[0]')" @barClick="index"></ScrollBar>
        <main>
            <!--公司简介-->
            <IntroduceModule class="scrollBar" :list="companyIntroduce"></IntroduceModule>
            <div class="line-line" v-if="!$store.state.screenType"></div>
            <!--使命意愿-->
            <MissionModule class="scrollBar" :list="hopeList"></MissionModule>
            <div class="line-line" v-if="!$store.state.screenType"></div>
            <!--业务架构-->
            <BusinessModule class="scrollBar" :list="structureList"></BusinessModule>
        </main>
        <main style="background-color:#1F1F1F;">
            <!--管理团队-->
            <TeamModule class="scrollBar" :list="managerTeams"></TeamModule>
        </main>
        <main>
            <div class="line-line"></div>
        </main>
        <LocationModule class="scrollBar" :list="country"></LocationModule>
        <Footer></Footer>
        <top></top>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import SwiperCom from '../../components/SwiperCom'
import ScrollBar from '../../components/ScrollBar'
import Footer from '../../components/Footer/Footers'
import TitleH from '../../components/Index/TitleH'
import { result } from '../../tools/ScrollBar'

import IntroduceModule from './IntroduceModule/IntroduceModule'
import MissionModule from './IntroduceModule/MissionModule'
import BusinessModule from './IntroduceModule/BusinessModule'
import TeamModule from './IntroduceModule/TeamModule'
import LocationModule from './IntroduceModule/LocationModule'

export default {
    name: "Introduce",
    mixins: [result],
    components: {
        Navbar,
        SwiperCom,
        ScrollBar,
        TitleH,
        Footer,
        IntroduceModule,
        MissionModule,
        BusinessModule,
        TeamModule,
        LocationModule
    },
    data () {
        return {
            meta: {},//head头部内容
            banners: [],
            companyIntroduce: '',//简介信息,
            country: [], //地址信息
            managerTeams: [],//管理团队
            structureList: [],//业务架构
            hopeList: []//使命意愿
        }
    },
    methods: {
        index (value) {
            this.scrollClick(value)
        },
        //请求head数据
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 2).then(res => {
                this.meta = res.data.data
                this.banners = res.data.data.banners
                //console.log(this.meta)
                this.$getMedia()
            })
        },
        //请求页面的所有数据
        getAllList () {
            this.$http.getIntroduce(this.$store.state.languageIndex).then(res => {
                //console.log(res.data.data)
                this.country = res.data.data.countrys
                this.companyIntroduce = res.data.data.companyIntroduce
                this.managerTeams = res.data.data.managerTeams
                this.structureList = res.data.data.structureList
                this.hopeList = res.data.data.hopeList
            })
        }

    },
    created () {
        this.getList()
        this.getAllList()
    },
    watch: {
        //监听vuex中的语言状态，当发生改变的时候，更新页面中的数据
        '$store.state.languageIndex' () {
            this.getList()
            this.getAllList()
        }
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------Introduce---------- --*/
.Introduce {
    font-size: 0;

    .swiper {
        padding-top: 6.25rem !important;

        ::v-deep [class^='swiper-button'] {
            display: none;
        }
    }


    main {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 7.5rem;
        box-sizing: border-box;

        .line-line {
            width: 94%;
            height: 0.1875rem;
            background-color: #C7C4C7;
            margin: 0 auto;
        }
    }
}

@import "m-introduce";
</style>
