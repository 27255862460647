<template>
    <div class="mission" :class="{m_mission:$store.state.screenType}" >
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[0].menuList[1]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="mission_content" v-show="!$store.state.screenType" v-if="list.length">
            <div class="mission_content_item">
                <span class="title">{{ list[0]?.title }}</span>
                <div class="items">
                    <div class="item" v-html="list[0]?.text"></div>
                </div>
            </div>
            <div class="mission_content_item none">
                <img :src="imgUrl(baseUrl,list[0]?.imgList[0].ImageUrl)" alt="">
            </div>
            <div class="mission_content_item none">
                <img :src="imgUrl(baseUrl,list[1]?.imgList[0].ImageUrl)" alt="">
            </div>
            <div class="mission_content_item">
                <span class="title">{{ list[1]?.title }}</span>
                <div class="items">
                    <div class="item" v-html="list[1]?.text"></div>
                </div>
            </div>
            <div class="mission_content_item">
                <span class="title">{{ list[2]?.title }}</span>
                <div class="items">
                    <div class="item flex" v-html="list[2]?.text"></div>
                </div>
            </div>
            <div class="mission_content_item none">
                <img :src="imgUrl(baseUrl,list[2]?.imgList[0].ImageUrl)" alt="">
            </div>
        </div>
        <div class="culture_content_text" v-if="$store.state.screenType">
            <div class="item-text" v-for="(value,index) in list">
                <h2>{{ value.title }}</h2>
                <div class="items">
                    <div class="item " v-html="value.text" :class="{flex:index===2}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "MissionModule",
    components: {
        TitleH
    },
    props: ['list'],
    data () {
        return {}
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------MissionModule---------- --*/
.mission {
    padding: 7.5rem 0;

    &_content {
        width: 100%;
        background-color: #F8F9FB;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 7.5rem;

        &_item {
            width: 50%;
            //height: 29.3125rem;
            padding: 2.625rem 4.375rem 0 4.375rem;
            box-sizing: border-box;
            overflow: hidden;

            .title {
                font-size: 2.25rem;
                font-weight: 800;
                position: relative;

                &:before {
                    position: absolute;
                    top: 104%;
                    left: -3%;
                    content: '';
                    width: 106%;
                    height: 0.125rem;
                    background-color: red;
                    border-radius: 0.125rem;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    top: 104%;
                    left: calc(52% - 0.28125rem);
                    border-style: solid;
                    border-width: 0.5625rem 0.375rem 0 0.375rem;
                    border-color: red transparent transparent transparent;
                }
            }

            .items {
                margin-top: 2.125rem;
                font-size: 1.875rem;
                line-height: 3rem;
                font-weight: lighter;

                p {
                    &:last-child {
                        margin-top: 1.125rem;
                    }
                }
            }

            .flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                ::v-deep p {
                    width: 50%;
                }
            }
        }

        .none {
            padding: 0;

            img {
                width: 100%;
            }
        }
    }


    .culture_content_text {
        background-color: #F0F0F0;
        padding: 2.625rem;
        margin-top: 4.625rem;

        .item-text {
            margin-bottom: 4.5625rem;

            h2 {
                font-size: 2.125rem;
            }


            .items {
                margin-top: 1.125rem;
                width: 100%;
                font-size: 1.5rem;
                line-height: 1.875rem;
                padding-bottom: 1rem;

                ::v-deep p {
                    &:last-child {
                        margin-top: 1rem !important;
                    }
                }
            }

            .flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                ::v-deep p {
                    width: 50%;
                    margin: 0;
                }
            }
        }
    }

}

.m_mission {
    padding: 0;
}

.item {
    font-family: 'AlibabaPuHuiTiL';
}
</style>
